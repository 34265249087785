<template>
    <el-select v-model="mineStatus"
               placeholder="请选择文章分类"
               @change="selectChange"
               clearable
    >
        <el-option  :value="mineStatusValue" style="height: auto;background: white">
            <el-tree :data="curData"
                     show-checkbox
                     check-strictly
                     :accordion="true"
                     node-key="id"
                     ref="tree"
                     default-expand-all
                     highlight-current
                     :props="defaultProps" @check-change="handleCheckChange"></el-tree>
        </el-option>
    </el-select>
</template>

<script>
    export default {
        name: "SelectTree",
        data(){
            return{
                mineStatus: "",
                mineStatusValue: "",
                curData:[],
                defaultProps: {
                    children: 'category_list',
                    label: 'title'
                },
            }
        },

        mounted(){
            this.getSelectTree();
        },

        methods:{
            async getSelectTree(){
                let data  =  await this.$api.getArticleCategoryList();
                this.curData = [];
                for(let item of data){
                    item.disabled = true;
                    this.curData.push(item)
                }
                // this.curData = data;
            },

            selectChange(e){
                let arrNew = [];
                let dataLength = this.mineStatusValue.length;
                let eLen = e.length;
                for(let i = 0; i< dataLength ;i++){
                    for(let j = 0; j < eLen; j++){
                        if(e[j] === this.mineStatusValue[i].label){
                            arrNew.push(this.mineStatusValue[i])
                        }
                    }
                }
                this.$refs.tree.setCheckedNodes(arrNew);//设置勾选的值
            },

            handleCheckChange(data, checked, indeterminate) {
                if (checked) {
                    let arr = [data.id];
                    this.$refs.tree.setCheckedKeys(arr);

                    this.mineStatusValue = data.id;
                    this.mineStatus = data.title;
                    let treeInfo =  this.$refs.tree.getCheckedKeys();
                    this.$emit('select-value', treeInfo);
                }
            },

            resetData(){
                this.mineStatusValue = '';
                this.mineStatus = '';
            },
        }
    }
</script>

<style scoped>

</style>