<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <el-row class="table-header" type="flex" justify="space-between">
          <el-col>
            <div style="display: flex;justify-content: space-between">
              <el-button type="primary" size="medium"
                         v-if="userPermissions.indexOf('article_create') != -1"
                         @click="addButton(0)">添加
              </el-button>
              <el-switch
                  v-model="isSort"
                  inactive-text="列表排序">
              </el-switch>
            </div>
          </el-col>
          <!--                    <el-col :span="5" :offset="1">-->
          <!--                        <change-question-bank></change-question-bank>-->
          <!--                    </el-col>-->
        </el-row>

        <el-row class="table-search">
          <el-form :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item v-for="(item,index) in searchForm" :label="item.title" :key="index">
              <el-autocomplete
                  v-model="formData[item.value]"
                  :placeholder="'请输入'+item.title"
                  :fetch-suggestions="querySearch"
                  :trigger-on-focus="false"
                  clearable
              ></el-autocomplete>
            </el-form-item>
            <el-form-item label="分类:">
              <select-tree
                  ref="tree"
                  @select-value="getSelectValue"
              ></select-tree>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSearchClick">搜索</el-button>
              <el-button @click="onResetSearchClick">重置</el-button>
            </el-form-item>
          </el-form>
        </el-row>

        <ApeTable ref="apeTable" :data="articleList" :columns="columns" :loading="loadingStatus"
                  :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row border>
          <!--                    <el-table-column-->
          <!--                            slot="second-column"-->
          <!--                            width="64"-->
          <!--                            label="序号">-->
          <!--                        <template slot-scope="scope">-->
          <!--                            <span>{{offset+scope.$index+1}}</span>-->
          <!--                        </template>-->
          <!--                    </el-table-column>-->
          <el-table-column
              v-if="buttonType=='icon'"
              width="200"
              label="操作"
              fixed="right"
          >
            <template slot-scope="scope">
              <span v-if="!isSort">
                  <el-tooltip effect="dark" content="分类" placement="top-start"
                              v-if="userPermissions.indexOf('article_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit-outline" type="primary"
                             @click="categoryButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="编辑" placement="top-start"
                            v-if="userPermissions.indexOf('article_edit') != -1">
                  <el-button size="mini" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>

                <el-tooltip effect="dark" content="删除" placement="top-start"
                            v-if="userPermissions.indexOf('article_delete') != -1">
                    <el-button size="mini" icon="el-icon-delete" @click="deleteButton(scope.row.id)"
                               type="danger"></el-button>
                </el-tooltip>
                </span>

              <span v-if="isSort">
                <el-tooltip effect="dark" content="置顶" placement="top-start">
                    <el-button size="mini" icon="el-icon-upload2" @click="onStickClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="置底" placement="top-start">
                    <el-button size="mini" icon="el-icon-download" @click="onUndersideClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="向上排序" placement="top-start">
                    <el-button size="mini" icon="el-icon-top" @click="onUpwardClick(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="向下排序" placement="top-start">
                    <el-button size="mini" icon="el-icon-bottom" @click="onDownwardClick(scope.row.id)"></el-button>
                </el-tooltip>
              </span>

              <!--                  <span>-->
              <!--                    <el-popover-->
              <!--                        -->
              <!--                        placement="top"-->
              <!--                        width="150"-->
              <!--                        v-model="scope.row.visible">-->
              <!--                      <p>确定要删除记录吗？</p>-->
              <!--                      <div style="text-align: right; margin: 0;">-->
              <!--                        <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
              <!--                        <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
              <!--                      </div>-->
              <!--                      <el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>-->
              <!--                    </el-popover>-->
              <!--                  </span>-->
              <!--                </el-tooltip>-->
            </template>
          </el-table-column>
          <el-table-column
              v-if="buttonType=='text'"
              width="160"
              label="操作"
              fixed="right"
          >
            <template slot-scope="scope">
              <span>

                  <el-button size="mini"
                             type="primary"
                             @click="categoryButton(scope.row.id)"
                             v-if="userPermissions.indexOf('article_edit') != -1">分类
                  </el-button>
                <el-button size="mini" v-if="userPermissions.indexOf('article_edit') != -1"
                           @click="editButton(scope.row.id)">编辑</el-button>
                <el-button size="mini" v-if="userPermissions.indexOf('article_delete') != -1" icon="el-icon-delete"
                           @click="deleteButton(scope.row.id)" type="danger">删除</el-button>
                <!--                <el-popover-->
                <!--                    v-if="userPermissions.indexOf('article_delete') != -1"-->
                <!--                    placement="top"-->
                <!--                    width="150"-->
                <!--                    v-model="scope.row.visible">-->
                <!--                  <p>确定要删除记录吗？</p>-->
                <!--                  <div style="text-align: right; margin: 0;">-->
                <!--                    <el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                <!--                    <el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                <!--                  </div>-->
                <!--                  <el-button slot="reference" type="danger" size="mini">删除</el-button>-->
                <!--                </el-popover>-->
              </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
    <select-special-topic
        :cur-article-id="curArticleId"
        :dialog-data="dialogData"
        @confirm="onSelectSpecialTopicConfirm"
    ></select-special-topic>
  </div>
</template>

<script>
const DEL = "del";
const NO_DEL = "no_del";
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import {mapGetters} from 'vuex'
import SelectSpecialTopic from './component/SelectSpecialTopic'
import SelectTree from '@/components/SelectTree'

export default {
  name: "Index",
  components: {
    PageHeaderLayout,
    ApeTable,
    SelectSpecialTopic,
    SelectTree
  },
  data() {
    return {
      isSort: false,
      loadingStatus: false,
      columns: [
        {
          title: '序号',
          value: 'order',
          width: 80
        },
        {
          title: '封面',
          type: 'image',
          value: 'cover_pic_url',
          width: 80
        },
        {
          title: '缩略图',
          type: 'image',
          value: 'thumb_pic_url',
          width: 80
        },
        // {
        //     title: '标题',
        //     value: [
        //         {label: 'ID：', value: 'id'},
        //         {label: '标题：', value: 'title_alias', value_alias: 'title'},
        //         {label: '作者：', value: 'author_name'},
        //     ],
        //     width:500
        // },
        {
          title: '标题',
          value: 'title',
          width: 360
        },
        {
          title: '作者',
          value: 'author_name',
          width: 120
        },
        {
          title: '关键字',
          value: 'keyword',
        },
        {
          title: '文章来源',
          value: 'source',
          width: 100
        },
        {
          title: '摘要',
          value: 'excerpt',
          width: 360
        },
        {
          title: '发布时间',
          value: 'created_at',
          width: 180
        }],
      // 表格列表数据
      articleList: [],
      searchCondition: {},
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
      },
      // 分页的offset,序号列使用
      offset: 0,
      // 已上传文件列表
      // uploadFileList: [],
      // 表单验证
      // rules: {
      //     title: [
      //         {required: true, message: '输入标题', trigger: 'blur'},
      //     ],
      //     type: [
      //         {required: true, message: '选择类型', trigger: 'blur'},
      //     ],
      //     is_new_win: [
      //         {required: true, message: '选择打开方式', trigger: 'blur'},
      //     ],
      //     is_show: [
      //         {required: true, message: '选择状态', trigger: 'blur'},
      //     ],
      //     image: [
      //         {required: true, message: '上传图片', trigger: 'blur', validator: this.apeUploaderVerify},
      //     ],
      // },
      // 抽屉数据，附件列表
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        title: '',
        width_height: '640px',
        show_footer: false
      },

      // 当前需要操作附件的文章id
      // currentArticleId: 0,

      //自动填充数据
      restaurants: [],

      searchForm: [
        {
          title: "标题:",
          value: "title"
        },
        {
          title: "关键字:",
          value: "keyword"
        },
        {
          title: "摘要:",
          value: "excerpt"
        },
      ],

      //分类弹框按钮
      dialogData: {
        visible: false,
        title: '选择专题分类',
        width: '40%',
        loading: false,
      },

      //文章id
      curArticleId: "",

      formData: {},
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType', "curChooseBankId"]),
  },
  watch: {
    "$route.matched": function (n, o) {
      if (n.length === 2) {
        this.initArticleList(DEL)
      }
    },

    'curChooseBankId'(newVal) {
      if (newVal) {
        this.initArticleList(DEL);
      }
    },
  },
  methods: {
    // 搜索
    onSearchClick() {
      let formData = {};
      for (let key in this.formData) {
        if (this.formData.hasOwnProperty(key)) {
          if (this.formData[key] !== "") {
            formData[key] = this.formData[key];
          }
        }
      }

      this.searchCondition = formData;
      this.$refs['apeTable'].resetCurPageSize();
      this.initArticleList(DEL)
    },

    // 切换页码操作
    async switchPaging() {
      this.initArticleList(NO_DEL)
    },

    // 响应添加按钮
    async addButton() {
      this.$router.push(this.$route.path + '/' + this.pagingData.total + '/create')
    },

    // 响应编辑按钮
    async editButton(id) {
      this.$router.push(this.$route.path + '/' + this.pagingData.total + '/' + id + '/edit')
    },

    //响应分类按按钮
    categoryButton(id) {
      this.curArticleId = id;
      this.dialogData.visible = true;
    },

    // 相应删除按钮
    async deleteButton(id) {
      this.$confirm('是否删除该条文章', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deleteArticle(id);
        if (info == 'ok') {
          this.$nextTick(() => {
            this.$message.success("删除成功");
            this.initArticleList(NO_DEL)
          })
        } else {
          this.$message.error(info)
        }
      })


    },

    // 初始化文章列表
    async initArticleList(type) {
      this.loadingStatus = true;
      let pagingInfo = this.$refs['apeTable'].getPagingInfo(type);

      if (Array.isArray(this.articleList) && this.articleList.length === 1) {
        if (pagingInfo.current_page > 1) {
          pagingInfo.current_page = pagingInfo.current_page-1
        }
      }
      let searchCondition = {};
      if (type === DEL) {
        pagingInfo.current_page = 1
        this.$refs['apeTable'].resetCurPageSize();
      }
      searchCondition = pagingInfo;
      for (let param in this.searchCondition) {
        searchCondition[param] = this.searchCondition[param];
      }

      let {list, pages} = await this.$api.getArticleList(searchCondition);
      this.articleList = [];
      this.$nextTick(() => {
        this.articleList = list
      });
      this.pagingData.total = pages.total;
      this.offset = pages.offset;
      this.loadingStatus = false
    },

    //搜索重置
    onResetSearchClick() {
      this.formData = {};
      this.$refs['apeTable'].resetCurPageSize();
      this.$refs['tree'].resetData();
      this.searchCondition = {};
      this.initArticleList(DEL);
    },

    querySearch(queryString, cb) {
      let restaurants = this.restaurants;

      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },

    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.match(queryString);
      };
    },

    getSelectValue(value) {
      if (value && value.length > 0) {
        this.formData.stc_id = value[0];
      }
    },

    onSelectSpecialTopicConfirm() {
      this.initArticleList(DEL)
    },

    //置顶
    async onStickClick(id) {
      let data = {
        id : id,
        type: 'top'
      }
      await this.$api.setArticleOrder(data)
      await this.initArticleList(DEL)
    },
    //置底
    async onUndersideClick(id) {
      let data = {
        id : id,
        type: 'bottom'
      }
      await this.$api.setArticleOrder(data)
      await this.initArticleList(NO_DEL)
    },
    //向上排序
    async onUpwardClick(id) {
      let data = {
        id : id,
        type: 'up'
      }
      await this.$api.setArticleOrder(data)
      await this.initArticleList(NO_DEL)
    },
    //向下排序
    async onDownwardClick(id) {
      let data = {
        id : id,
        type: 'down'
      }
      await this.$api.setArticleOrder(data)
      await this.initArticleList(NO_DEL)
    },

  },

  mounted() {
    this.initArticleList(DEL)
  }
}
</script>

<style lang="stylus" scoped>
.table-header
  margin-bottom 12px

.el-input-group__prepend, .el-input-group__append
  background #ffffff
  padding 0 12px

.el-input-group__append
  color #ffffff
  background #1890ff

.el-popover .el-checkbox-group .el-checkbox
  margin-left 0px
  margin-right 12px

/deep/ .el-switch__label
  color #0099ff
</style>
