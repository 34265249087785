<template>
    <ModalDialog :dialogData="dialogData" @dialogConfirm="handleConfirm" @dialogClose="dialogClose">
        <template slot="content">
            <el-tree
                    :data="specialTopicData"
                    show-checkbox
                    default-expand-all
                    :default-checked-keys="defaultCheckedKeys"
                    node-key="id"
                    ref="tree"
                    @check-change="onTreeCheckChange"
                    highlight-current
                    style="z-index: 999"
                    :props="defaultProps">
            </el-tree>
        </template>
    </ModalDialog>
</template>

<script>
    import ModalDialog from '@/components/ModalDialog'

    export default {
        name: "SelectSpecialTopic",
        props: {
            curArticleId: {
                type: String,
                default: ""
            },
            dialogData: {
                type: Object,
                default: function () {
                    return {
                        visible: false,
                        title: '选择专题分类',
                        width: '40%',
                        loading: false,
                    }
                }
            }
        },

        components: {
            ModalDialog,
        },
        data() {
            return {
                specialTopicData: [],
                defaultCheckedKeys: [],// 默认选中分类
                formData: {},
                defaultProps: {
                    children: 'category_list',
                    label: 'title'
                },
            }
        },
        mounted() {
            this.initArticleCategory();
            console.log('tes1t');
        },
        methods: {
            //选择专题分类确定
            async handleConfirm() {
                if (!this.curArticleId) {
                    this.$message("该文章不存在");
                    return;
                }
                this.dialogData.loading = true;
                let {info} = await
                    this.$api.getArticleInfo(this.curArticleId);
                this.formData = info;


                this.formData.category_list = this.specialTopicData;
                let id = await
                    this.$api.saveArticle(this.formData);
                if (id) {
                    this.$message.success('修改成功');
                    this.dialogData.visible = false;
                    this.formData = {};
                } else {
                    this.$message.success('修改失败');
                }

                this.dialogData.loading = false;
                this.$emit('confirm');
            },


            async initArticleCategory() {
                console.log('this.cirArticleId', this.curArticleId);
                if (this.curArticleId) {
                    this.getArticleInfo();
                } else {
                    let data = await this.$api.getArticleCategoryList();
                    this.specialTopicData = data;
                }
            },

            //获取文章信息
            async getArticleInfo() {
                let {info} = await
                    this.$api.getArticleInfo(this.curArticleId);
                this.formData = info;
                this.specialTopicData = info.category_list;
                for (let item of this.specialTopicData) {
                    if (item.category_list && item.category_list.length > 0) {
                        for (let category of item.category_list) {
                            if (category.checked) {
                                this.defaultCheckedKeys.push(category.id);
                            }
                        }
                    }
                }
            },

            //选择专题分类关闭
            dialogClose() {
                this.dialogData.visible = false;
                this.formData = {};
                this.defaultCheckedKeys = [];
            },

            async getSpecialTopicList() {
                this.dialogData.loading = true;
                let pageData = {
                    page_size: 1000,
                    current_page: 1
                };
                let {list, pages} = await
                    this.$api.getSpecialTopicList(pageData);

                if (list.length > 0) {
                    for (let specialTopic of list) {
                        let id = specialTopic.id;
                        let object = {
                            id: specialTopic.id,
                            label: specialTopic.title
                        };
                        this.getSpecialTopicCategoryList(id, object)
                    }
                }

                this.dialogData.loading = false;
                this.dialogData.visible = false;
            },

            //获取专题分类
            async getSpecialTopicCategoryList(id, specialTopic) {
                let pageData = {
                    page_size: 1000,
                    current_page: 1
                };

                if (id) {
                    pageData.st_id = id;
                }

                let {list, pages} = await
                    this.$api.getSpecialTopicCategoryList(pageData);

                if (list.length > 0) {
                    let specialTopicCategory = [];
                    for (let category of list) {
                        let object = {
                            id: category.id,
                            label: category.title
                        };
                        specialTopicCategory.push(object)
                    }
                    specialTopic.children = specialTopicCategory;
                    this.specialTopicData.push(specialTopic);
                }

                this.dialogData.loading = false;
            },

            onTreeCheckChange(data, isChecked, leafCheck) {
                if (data.hasOwnProperty('checked')) {
                    data.checked = isChecked;
                }
            },
        },

        watch: {
            'curArticleId'(newVal) {
                this.initArticleCategory();
            }
        }
    }
</script>

<style scoped>

</style>